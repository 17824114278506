/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
// Replace above style with this css file to enable RTL styles
@import './assets/sass/plugins.scss';
// @import "./assets/css/style.rtl.css";
@import "./assets/sass/style.angular.scss";

@import "~@ng-select/ng-select/themes/default.theme.css";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

@import "~bootstrap/dist/css/bootstrap.min.css";

@import "./assets/plugins/keenicons/duotone/style.css";
@import "./assets/plugins/keenicons/outline/style.css";
@import "./assets/plugins/keenicons/solid/style.css";

@import 'node_modules/@ali-hm/angular-tree-component/css/angular-tree-component.css';