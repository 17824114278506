.swal-loading {
  .swal2-popup {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
  }
}

.hover {
  cursor: pointer !important;
}

// scroll-bar style
::-webkit-scrollbar {
  background: $primary-light !important;
  width: 10px !important;
  height: 10px !important;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 200, 0, 0.5) !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-track {
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb:horizontal {
  background: rgba(255, 200, 0, 0.5) !important;
  border-radius: 10px;
}

/* datepicker scss */
.bs-datepicker .bs-datepicker-head {
  background-color: #f5bd18 !important;
}

.bs-datepicker-body table td.active-week span:hover {
  background-color: #f5bd18 !important;
}

.bs-datepicker-container {
  padding: 0px !important;
}

.bs-datepicker-body table th {
  color: #ffd101;
}

.bs-datepicker-head,
.bs-datepicker-head,
.bs-datepicker button:active,
.bs-datepicker-body table td span.selected,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*="select-"]:after,
.bs-datepicker-body table td[class*="select-"] span:after,
.bs-datepicker-body table td.active-week span:hover {
  background-color: #f5bd18 !important;
}

.bs-datepicker-body table td.week span {
  color: #f5bd18 !important;
}

.custom-today-class {
  border: #f5bd18 1px solid;
}

//datepicker scss end

/* ng-select scss */
.ng-select.ng-select-single .ng-select-container {
  height: 43px;
  border-radius: 0.475rem;
  border-color: #e7e9f1;
  font-size: 1.1rem;
  font-weight: 500;
  color: #5E6278;
  margin-right: 1rem;
}

ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  padding-left: 6px;

}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-top: 4px;
  padding-left: 12px;

}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  padding-left: 5px;
  color: #5E6278;
}

.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-placeholder,
.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-value {
  color: #999;
  ;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border-color: #e7e9f1;
  box-shadow: none;

}

.ng-select.invalid {
  border-color: red;
}

.rounded-4 {

  .modal-content,
  .swal2-modal {
    border-radius: 1rem !important;
  }
}

a {
  color: #343a40;
}

//ng-Multiselect 
.c-btn {
  font-size: 13px !important;
  border-radius: 5px !important;
}

.c-token {
  background: #f5bd18 !important;
  max-width: 100px !important;
  height: 23px;
  overflow: hidden;
}

.c-btn.disabled {
  background-color: #eff2f5 !important;
  color: #cccccd !important;
}

.arrow-down,
.arrow-up {
  display: none;
}

.dropdown-list {
  padding-top: 0px !important;
}

.selected-item {
  background-color: #fff !important;
}

.selected-list {
  max-height: 40px;
  // overflow: hidden;
}

.form-check-input {
  border-color: #ababab !important;
}

.form-check-input:checked {
  border-color: #009ef7 !important;
}

@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px #f5bd18;
  }

}

//ngx-select-dropdown
.ngx-select-dropdown-phone {
  .ngx-dropdown-list-container {
    min-width: 250px !important;

    .available-item {
      padding: 3px 5px !important;
    }

    .selected-items {
      margin-top: 0.2rem !important;
      margin-bottom: 0.2rem !important;

      .selected-item {
        padding: 3px 5px !important;
      }
    }

    hr {
      margin: 0.2rem 0 !important;
    }

    // .selected-items,hr{
    //   display:none;
    //   visibility: hidden;
    // }
  }

  .ngx-dropdown-button {
    border: none !important;
    margin-top: 2px !important;
    background-color: transparent !important;
  }

  .nsdicon-angle-down {
    margin-top: 1px !important;
  }
}

//tranparent-black-shade-bg
.bg-tranparent-black {
  background: #00000040 !important;
}


// Tablet & mobile modes
@include media-breakpoint-down(sm) {
  .app-content {
    max-width: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: $app-content-padding-x-mobile;
    padding-right: $app-content-padding-x-mobile;
  }
}

.border-separator {
  border-bottom: 1px solid #DBDFE9;
}

.label-caveat {
  font-family: caveat, Inter, Helvetica, "sans-serif" !important;
}

.cardBgTexture {
  background-size: auto;
  background-repeat: repeat;
}

.cardBgTexture.grid-primary {
  background-image: linear-gradient(to top right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0)), url('/assets/media/images/grid-primary.png');
}

.cardBgTexture.grid-secondary {
  background-image: linear-gradient(to top right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0)), url('/assets/media/images/grid-secondary.png');
}

.cardBgTexture.grid-brand {
  background-image: linear-gradient(to top right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0)), url('/assets/media/images/grid-brand.png');
}

.custom-nav-btn-tab {
  border: 1px solid #ccc;
}

.container-cards-horizontal {
  overflow-y: hidden !important;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

.container-cards-horizontal::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}