//
// Brand Sidebar(used by Brand Sidebar layout only)
//

[data-kt-app-layout="brand-sidebar"] {
    .app-sidebar {
        background-color: var(--kt-app-sidebar-light-bg-color);
        border-right: 1px solid var(--kt-app-sidebar-light-separator-color);
        

        .hover-scroll-overlay-y {        
            @include scrollbar-color(var(--kt-app-sidebar-light-scrollbar-color), var(--kt-app-sidebar-light-scrollbar-color-hover));
        }

        .menu {
            font-weight: $font-weight-semibold;

            .menu-item {                    
                .menu-heading {
                    color: var(--kt-app-sidebar-light-menu-heading-color) !important;
                }
                .menu-link {
                    .menu-title{
                        font-weight: 600;
                        font-size: 1rem;
                    }
                }

                @include menu-link-default-state( 
                    $title-color: var(--kt-gray-700), 
                    $icon-color: var(--kt-gray-500), 
                    $bullet-color: var(--kt-gray-500), 
                    $arrow-color: var(--kt-gray-500),
                    $bg-color: null  
                );
                
                @include menu-link-hover-state( 
                    $title-color: var(--kt-brand), 
                    $icon-color: var(--kt-brand), 
                    $bullet-color: var(--kt-brand), 
                    $arrow-color: var(--kt-brand), 
                    $bg-color: null
                );
                
                @include menu-link-show-state( 
                    $title-color: var(--kt-brand), 
                    $icon-color: var(--kt-brand), 
                    $bullet-color: var(--kt-brand), 
                    $arrow-color: var(--kt-brand), 
                    $bg-color: null
                );
                
                @include menu-link-here-state( 
                    $title-color: var(--kt-brand), 
                    $icon-color: var(--kt-brand), 
                    $bullet-color: var(--kt-brand), 
                    $arrow-color: var(--kt-brand), 
                    $bg-color: null
                );

                @include menu-link-active-state( 
                    $title-color: $white,
                    $icon-color: $white,
                    $bullet-color: $white,
                    $arrow-color: $white,
                    $bg-color: var(--kt-brand)
                );
            }
        }
    } 
}